import { sendRequestWithToken } from "../helpers/tokenManager";
import {
  AllData,
  IGetDataAPIResponse,
  IInstallment,
  IRecordByCategory,
  IRecordCategory,
  ITransaction,
} from "../Types";

// Takes setData and setLoading hooks, alogn with token as arguments,
// attempts to access "api/get_data"

export const fetchData = async (
  setData: React.Dispatch<React.SetStateAction<AllData | undefined>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  token: string
) => {
  setLoading(true);
  try {
    const res: IGetDataAPIResponse = await sendRequestWithToken(
      "api/get_data",
      token
    );
    setData(res.data);
  } catch (e) {
    alert(`Something is wrong, error is ${JSON.stringify(e)}`);
  }
  setLoading(false);
};

// Takes installments, month (indexed from 1-12) and year,
// removes those with end_date before given month
// (meaning it removes already paid off installments)
export const instMonthFilter = (
  list: IInstallment[],
  current_month: number,
  current_year: number
) => {
  // Find current month
  const allInstallments: IInstallment[] = [];
  const currentDate: Date = new Date(current_year, current_month - 1, 1);
  list.forEach((elem) => {
    allInstallments.push({
      ...elem,
      "Start Date": new Date(elem["Start Date"]),
      "End Date": new Date(elem["End Date"]),
    });
  });
  // Must end after current month
  // Must begin before next month
  const startDateLatest = new Date(currentDate);
  startDateLatest.setMonth(startDateLatest.getMonth() + 1);
  let returnData = allInstallments.filter(
    (elem) =>
      elem["End Date"] >= currentDate && elem["Start Date"] < startDateLatest
  );
  returnData = returnData.sort((a, b) =>
    a["Start Date"] < b["Start Date"] ? 1 : -1
  );
  return returnData;
};

// Takes Transact_Exp or Transact_Inc, month (indexed from 1-12) and year,
// removes those not in given month
export const transactMonthFilter = (
  list: ITransaction[],
  current_month: number,
  current_year: number
) => {
  list.forEach((elem) => {
    elem.Date = new Date(elem.Date);
  });
  list = list.filter(
    (elem) =>
      elem.Date.getMonth() + 1 === current_month &&
      elem.Date.getFullYear() === current_year
  );
  return list;
};

// Computes total spending in given month, year
// for each cat & subcat including installments

export const computeCatTotals = (
  data: AllData,
  current_month: number,
  current_year: number
) => {
  // Eliminate those not in current month
  const expense = transactMonthFilter(
    data.Transactions.Transact_Exp,
    current_month,
    current_year
  );
  const income = transactMonthFilter(
    data.Transactions.Transact_Inc,
    current_month,
    current_year
  );
  const installments = calculateAndAppendInstallmentData(
    instMonthFilter(data.Installments, current_month, current_year),
    current_month,
    current_year
  );
  // Calculate total spending in month
  const expenseCats: IRecordCategory = {};

  const incomeCats: IRecordCategory = {};

  const RecordsByCategory: IRecordByCategory = {
    Expense: expenseCats,
    Income: incomeCats,
  };
  // Populate RecordsByCategory: call populateCats by (RBC, data, is_expense)
  populateCats(RecordsByCategory, expense, true);
  populateCats(RecordsByCategory, income, false);
  populateCats(RecordsByCategory, installments, true);

  return RecordsByCategory;
};

const populateCats = (
  RecordsByCategory: IRecordByCategory,
  in_data: ITransaction[] | IInstallment[],
  is_expense: boolean
) => {
  in_data.forEach((transaction: ITransaction | IInstallment) => {
    const data_type: "Expense" | "Income" = is_expense ? "Expense" : "Income";

    // Add correct value for installments
    const amount: string =
      transaction["Installment Amount"] ?? transaction.Amount;

    // Initialize in case the cat and/or subcat was not added before
    if (!RecordsByCategory[data_type][transaction.Category]) {
      RecordsByCategory[data_type][transaction.Category] = {
        [transaction.Subcategory]: 0,
      };
    }

    // += with undef results in NaN, following is logic to decide whether to use += or =
    if (
      RecordsByCategory[data_type][transaction.Category][
        transaction.Subcategory
      ]
    ) {
      RecordsByCategory[data_type][transaction.Category][
        transaction.Subcategory
      ] += parseFloat(amount);
    } else {
      RecordsByCategory[data_type][transaction.Category][
        transaction.Subcategory
      ] = parseFloat(amount);
    }
  });
};

export const calculateAndAppendInstallmentData = (
  in_data: IInstallment[],
  current_month: number,
  current_year: number
) => {
  const returnData: CalculatedInstallment[] = [];
  const current_date: Date = new Date(current_year, current_month - 1, 1);
  in_data.forEach((elem) => {
    // Calculate remaining installments
    const remaining_installments: number = monthDiff(
      current_date,
      elem["End Date"]
    );
    // Add 1 to count the initial
    const no_of_installments: number =
      monthDiff(elem["Start Date"], elem["End Date"]) + 1;
    // Compute and format with "/" in the middle
    const currTotalString = [
      no_of_installments - remaining_installments,
      no_of_installments,
    ].join("/");
    // To display with 2 decimal digits
    const installmentAmountString = (
      parseFloat(elem["Amount"]) / no_of_installments
    ).toFixed(2);
    returnData.push({
      ...elem,
      "Current/Total": currTotalString,
      "Installment Amount": installmentAmountString,
    });
  });
  return returnData;
};

// Function to return number of months between 2 dates
// Adapted from  https://stackoverflow.com/questions/2536379/difference-in-months-between-two-dates-in-javascript
export const monthDiff = (d1: Date, d2: Date): number => {
  let months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  // Disallow negative results
  return months <= 0 ? 0 : months;
};

// Taken from https://www.jacklmoore.com/notes/rounding-in-javascript/#:~:text=The%20most%20common%20solutions%20for,round()%20.
export const round = (value: number, decimals: number) => {
  // TODO: Find a TS-Compatible solution for this
  /* eslint-disable */
  // @ts-ignore
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  /* eslint-enable */
};

export interface CalculatedInstallment extends IInstallment {
  "Current/Total": string;
  "Installment Amount": string;
}
