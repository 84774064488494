import React from "react";
import { Dashboard } from "./Dashboard/Dashboard";
import { MyNav } from "./MyNav";

/* This is the entry point for logged in users */

export const Home = () => {
  return (
    <div>
      <MyNav />
      <Dashboard />
    </div>
  );
};
