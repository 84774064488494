import React, { ReactElement } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import {
  CalculatedInstallment,
  calculateAndAppendInstallmentData,
} from "../../helpers/helpers";
import { tableAmountSort, sendDeleteRequest } from "./Details";
import { IInstallment } from "../../Types";

export const formatInstallmentDates = (
  installments: CalculatedInstallment[]
) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  return installments.map((installment) => ({
    ...installment,
    "Start Date": installment["Start Date"].toLocaleDateString(
      "tr-TR",
      options
    ),
    "End Date": installment["End Date"].toLocaleDateString("tr-TR", options),
  }));
};

const dateSort = (a: string, b: string, order: string) => {
  const timestampA = parseDateStringToTimestamp(a);
  const timestampB = parseDateStringToTimestamp(b);

  return order === "asc" ? timestampA - timestampB : timestampB - timestampA;
};

const dateSortFunc = (a: string, b: string, order: string) =>
  dateSort(a, b, order);

const tableAmountSortFunc = (a: string, b: string, order: string) =>
  tableAmountSort(a, b, order);

const parseDateStringToTimestamp = (date: string) => {
  const [day, month, year] = date.split(".").map((part) => parseInt(part));
  return new Date(year, month, day).getTime();
};

const renderDeleteButton = (
  cell,
  row: { id: number },
  rowIndex,
  props: {
    token: string;
    forceRender: boolean;
    setForceRender: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }
) => (
  <Button
    variant="outline-danger"
    size="sm"
    onClick={() =>
      sendDeleteRequest(
        row.id,
        true,
        props.token,
        props.forceRender,
        props.setForceRender,
        props.setLoading
      )
    }
  >
    Delete
  </Button>
);

const defaultSorted = [
  {
    dataField: "Start Date",
    order: "asc",
  },
];

export const InstTable = (props: InstallmentTableProps): ReactElement => {
  const installmentData = formatInstallmentDates(
    calculateAndAppendInstallmentData(
      props.data,
      props.currentMonth,
      props.currentYear
    )
  );
  const installmentColumns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "Start Date",
      text: "Start Date",
      sort: true,
      sortFunc: dateSortFunc,
    },
    {
      dataField: "End Date",
      text: "End Date",
      sort: true,
      sortFunc: dateSortFunc,
    },
    {
      dataField: "Category",
      text: "Category",
      sort: true,
    },
    {
      dataField: "Subcategory",
      text: "Subcategory",
    },
    {
      dataField: "Details",
      text: "Details",
    },
    {
      dataField: "Pay Method",
      text: "Pay Method",
      sort: true,
    },
    {
      dataField: "Installment Amount",
      text: "Installment Amount",
      sort: true,
      sortFunc: tableAmountSortFunc,
    },
    {
      dataField: "Amount",
      text: "Total Amount",
      sort: true,
      sortFunc: tableAmountSortFunc,
    },
    {
      dataField: "Current/Total",
      text: "Current/Total",
      sort: false,
    },
    {
      dataField: "button",
      text: "Button",
      formatter: renderDeleteButton,
      formatExtraData: props,
    },
  ];

  return (
    <div>
      <br />
      <BootstrapTable
        keyField="id"
        data={installmentData}
        columns={installmentColumns}
        striped
        hover
        bootstrap4
        condensed
        defaultSorted={defaultSorted}
      />
    </div>
  );
};

interface InstallmentTableProps {
  data: IInstallment[];
  currentMonth: number;
  currentYear: number;
  token: string;
  forceRender: boolean;
  setForceRender: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
