import React, { ReactElement } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // Legend,
  // ResponsiveContainer,
} from "recharts";
import { round } from "../../helpers/helpers";
import { IRecordCategory } from "../../Types";

// Example data for chart formatting, see inside DashGraph() for instructions
// const data = [
//   {
//     Name: "Food",
//     Cost: 2170,
//   },
//   {
//     Name: "Transportation",
//     Cost: 367,
//   },
//   {
//     Name: "Gifts",
//     Cost: 412,
//   },
//   {
//     Name: "Housing",
//     Cost: 2780,
//   },
//   {
//     Name: "Loans",
//     Cost: 1890,
//   },
//   {
//     Name: "Personal Care",
//     Cost: 450,
//   },
//   {
//     Name: "Shopping",
//     Cost: 605.12,
//   },
// ];

// Function to generate the chart, supply SpendingByCategory
const BarGenerator = (SpendingByCategory: IRecordCategory) => {
  const expense_data: Array<{ Name: string; Cost: number }> = [];
  // Iterate each category
  Object.keys(SpendingByCategory).forEach((category) => {
    let cat_total = 0;
    // Sum all subcats of each categort
    Object.keys(SpendingByCategory[category]).forEach((subcategory) => {
      cat_total += SpendingByCategory[category][subcategory];
    });
    // Convert to recharts format [{Name: $Name_1, Cost: $Cost_1}, {Name: $Name_2, Cost: $Cost_2}, ...]
    expense_data.push({ Name: category, Cost: round(cat_total, 0) });
  });
  // Sort by spending desc
  expense_data.sort((a, b): number => {
    const keyA = a.Cost;
    const keyB = b.Cost;
    return keyA < keyB ? 1 : -1;
  });
  return expense_data;
};

export const DashGraph = (props: {
  SpendingByCategory: IRecordCategory;
}): ReactElement => {
  // Enable following line and disable the next one to use dummy data
  // const expense_data: any = data;
  const expense_data = BarGenerator(props.SpendingByCategory);
  return (
    <ResponsiveContainer width="95%" height={350}>
      <BarChart
        data={expense_data}
        margin={{
          top: 10,
          right: 0,
          left: 0,
          bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="Cost" stackId="a" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};
