export enum Page {
  WELCOME = "welcome",
  REGISTER = "register",
  HOME = "home",
  DETAILS = "details",
  PAY = "pay",
  CATEGORIES = "categories",
}

export interface ILoginAPIResponse {
  message: string;
  auth_token?: string;
}

export interface ITimeAPIResponse {
  time: string;
}

export interface ICatDict {
  [key: string]: string[];
}

export interface ITransaction {
  id: number;
  Date: Date;
  Category: string;
  Subcategory: string;
  Details: string;
  Amount: string;
  "Pay Method": string;
}

export interface IInstallment extends Omit<ITransaction, "Date"> {
  "Start Date": Date;
  "End Date": Date;
  "Installment Amount": string;
}

export interface ITransactList {
  Transact_Exp: ITransaction[];
  Transact_Inc: ITransaction[];
}
export interface IGetDataAPIResponse {
  status: number;
  data: AllData;
  message?: string;
}

export interface AllData {
  Categories: {
    Expense: ICatDict[];
    Income: ICatDict[];
  };
  PayMethods: string[];
  Transactions: ITransactList;
  Installments: IInstallment[];
}

export interface IRecordByCategory {
  Expense: IRecordCategory;
  Income: IRecordCategory;
}

export interface IRecordCategory {
  [key: string]: IRecordSubcategory;
}

export interface IRecordSubcategory {
  [key: string]: number;
}
