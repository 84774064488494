import { ILoginAPIResponse } from "../Types";

export const getToken = async (
  email: string,
  password: string,
  setToken: (value: string) => void
): Promise<boolean> => {
  const data = { email, password };
  const resRaw = await fetch("/auth/login", {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const res = (await resRaw.json()) as ILoginAPIResponse;
  if (res.auth_token) {
    setToken(res.auth_token);
    return Promise.resolve(true);
  } else {
    alert(`Login failed: message is: ${res.message}`);
    return Promise.reject(`${JSON.stringify(res)}`);
  }
};

export interface ResponseData {
  status: number;
  message: string;
  code: number;
  data;
}

// Use this to send data with JWT, by providing JSON data, route as a string, and token state value
export const sendRequestWithToken = async <T, W = ResponseData>(
  route: string,
  token: string,
  data?: T
): Promise<W> => {
  const routeWithApi = appendApiToRouteIfMissing(route);
  const res = await fetch(routeWithApi, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer: ${token}`,
    },
    body: JSON.stringify(data),
  });
  const resData: W = await res.json();
  if (res.ok) {
    return Promise.resolve(resData);
  } else {
    alert(
      `Something went wrong:\nStatus: ${res.status}\nMessage: ${res.statusText}\nHint: ${resData}`
    );
    return Promise.reject(res);
  }
};

const appendApiToRouteIfMissing = (route: string): string => {
  if (route.startsWith("api/")) {
    return route;
  } else {
    return `api/${route}`;
  }
};
