import React, { ReactElement, useState, useEffect, useContext } from "react";
import { sendRequestWithToken } from "../../helpers/tokenManager";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { MyNav } from "../MyNav";
import { InstTable } from "./InstTable";
import { CatTable } from "./CatTable";
import { PayTable } from "./PayTable";
import {
  transactMonthFilter,
  instMonthFilter,
  computeCatTotals,
  calculateAndAppendInstallmentData,
} from "../../helpers/helpers";
import { UserContext } from "../ContextProviders/UserProvider";
import {
  AllData,
  IGetDataAPIResponse,
  IInstallment,
  IRecordByCategory,
  ITransaction,
} from "../../Types";
import { TransactionTable } from "./TransactionTable";
import { Loading } from "../Loading";

const current_date: Date = new Date();
const current_month: number = current_date.getMonth() + 1;
const current_year: number = current_date.getFullYear();

export const sendDeleteRequest = async (
  id: number,
  is_installment: boolean,
  token: string,
  forceRender: boolean,
  setForceRender: React.Dispatch<React.SetStateAction<boolean>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const data = {
    id,
    is_installment,
  };
  try {
    await sendRequestWithToken("api/del_transact", token, data);
    setLoading(true);
    setForceRender(!forceRender);
  } catch (e) {
    alert(`${JSON.stringify(e)}`);
  }
};

export const tableAmountSort = (
  a: string,
  b: string,
  order: string
): number => {
  const num_a: number = parseFloat(a);
  const num_b: number = parseFloat(b);
  if (order === "asc") {
    return num_a - num_b;
  }
  return num_b - num_a; // desc
};

export const handleMonthChange = (
  selectedMonth: { month: number; year: number },
  setSelectedMonth: React.Dispatch<
    React.SetStateAction<{ month: number; year: number }>
  >,
  decrease_month: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const old_date = new Date(selectedMonth.year, selectedMonth.month - 1);
  if (decrease_month) {
    old_date.setMonth(old_date.getMonth() - 1);
    setSelectedMonth({
      month: old_date.getMonth() + 1,
      year: old_date.getFullYear(),
    });
    setLoading(true);
  } else {
    old_date.setMonth(old_date.getMonth() + 1);
    setSelectedMonth({
      month: old_date.getMonth() + 1,
      year: old_date.getFullYear(),
    });
    setLoading(true);
  }
};

export const Details: React.FC = (): ReactElement => {
  // Fetch categories, subcategories and pay_methods

  const { token } = useContext(UserContext);
  const [data, setData] = useState<AllData>(); // eslint-disable-line
  const [loading, setLoading] = useState(true);
  const [forceRender, setForceRender] = useState(false);
  const [expTableData, setExpTableData] = useState<ITransaction[]>();
  const [incTableData, setIncTableData] = useState<ITransaction[]>();
  const [instTableData, setInstTableData] = useState<IInstallment[]>();
  const [catTableData, setCatTableData] = useState<IRecordByCategory>();
  const [selectedMonth, setSelectedMonth] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });
  // Return object will have {"Expense", "Income", "PayMethods" and nested values as categories-subcategories}
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: IGetDataAPIResponse = await sendRequestWithToken(
          "api/get_data",
          token
        );

        setData(res.data);
        // Filter by month, default to last month
        const filtered_exp = transactMonthFilter(
          res.data.Transactions.Transact_Exp,
          selectedMonth.month,
          selectedMonth.year
        );
        const filtered_inst = calculateAndAppendInstallmentData(
          instMonthFilter(
            res.data.Installments,
            selectedMonth.month,
            selectedMonth.year
          ),
          current_month,
          current_year
        );
        const filtered_inc = transactMonthFilter(
          res.data.Transactions.Transact_Inc,
          selectedMonth.month,
          selectedMonth.year
        );
        const RecordsByCategory = computeCatTotals(
          res.data,
          selectedMonth.month,
          selectedMonth.year
        );
        // setData to formatted filter
        setExpTableData(filtered_exp);
        setIncTableData(filtered_inc);
        setInstTableData(filtered_inst);
        setCatTableData(RecordsByCategory);
        setLoading(false);
      } catch (e) {
        alert(`${JSON.stringify(e)}}`);
      }
    };
    try {
      fetchData();
    } catch (e) {
      alert(`error caught ${JSON.stringify(e)}`);
    }
  }, [token, forceRender, selectedMonth]);

  if (loading === false) {
    return (
      <div>
        <MyNav />
        <div className="App">
          <header className="App-header">
            <p />
            <h1>Details</h1>
          </header>
          <br></br>
          <Container>
            <Row className="justify-content-md-center">
              <div>
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    handleMonthChange(
                      selectedMonth,
                      setSelectedMonth,
                      true,
                      setLoading
                    )
                  }
                >
                  &lt;&lt;
                </Button>
                {"  "}
                {selectedMonth.month}/{selectedMonth.year}
                {"  "}
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    handleMonthChange(
                      selectedMonth,
                      setSelectedMonth,
                      false,
                      setLoading
                    )
                  }
                >
                  &gt;&gt;
                </Button>
              </div>
            </Row>
            <Row>
              <Col>
                <br />
                <Tab.Container id="Details-tabs" defaultActiveKey="expense">
                  <Nav variant="pills" className="justify-content-center">
                    <Nav.Link eventKey="expense">Expense</Nav.Link>
                    <Nav.Link eventKey="installments">Installments</Nav.Link>
                    <Nav.Link eventKey="catTable">Categories</Nav.Link>
                    <Nav.Link eventKey="payTable">Pay Methods</Nav.Link>
                    <Nav.Link eventKey="income">Income</Nav.Link>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="expense">
                      <TransactionTable
                        data={expTableData!}
                        token={token}
                        forceRender={forceRender}
                        setForceRender={setForceRender}
                        setLoading={setLoading}
                        showPayMethod={true}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="installments">
                      <InstTable
                        data={instTableData!}
                        token={token}
                        forceRender={forceRender}
                        setForceRender={setForceRender}
                        setLoading={setLoading}
                        currentMonth={selectedMonth.month}
                        currentYear={selectedMonth.year}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="catTable">
                      <CatTable data={catTableData!} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="payTable">
                      <PayTable
                        expData={expTableData!}
                        instData={instTableData! as unknown as ITransaction[]}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="income">
                      <TransactionTable
                        data={incTableData!}
                        token={token}
                        forceRender={forceRender}
                        setForceRender={setForceRender}
                        setLoading={setLoading}
                        showPayMethod={false}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};
