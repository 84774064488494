import React, { useContext, useEffect, useState } from "react";
import { ResponseData, sendRequestWithToken } from "../helpers/tokenManager";
import { MyNav } from "./MyNav";
import { Button, Container, Form } from "react-bootstrap";
import { optionMaker } from "./Dashboard/Dashboard";
import { UserContext } from "./ContextProviders/UserProvider";
import { Loading } from "./Loading";

interface IPayMethod {
  id: number;
  Name: string;
}

interface IGetPayResponse extends ResponseData {
  data: {
    PayMethods: IPayMethod[];
  };
}

const fetchPay = async (
  setPay: React.Dispatch<React.SetStateAction<IPayMethod[]>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  token: string
) => {
  setLoading(true);
  try {
    const res: IGetPayResponse = await sendRequestWithToken(
      "api/get_pay",
      token
    );
    console.log("API Response:", res);
    console.log("API Response Data:", res.data);
    if (res.data.PayMethods) {
      setPay(res.data.PayMethods);
    } else {
      console.error("PayMethods not found in response data");
    }
  } catch (e) {
    alert(`error caught ${JSON.stringify(e)}`);
    console.error("API Request Error:", e);
  }
  setLoading(false);
};

export const Pay = () => {
  const { token } = useContext(UserContext);
  const [pay, setPay] = useState<IPayMethod[]>([]);
  const [loading, setLoading] = useState(true);
  const [forceRender, setForceRender] = useState(false);
  const [newPayName, setNewPayName] = useState("");
  const [selectedPay, setSelectedPay] = useState("");

  useEffect(() => {
    fetchPay(setPay, setLoading, token);
  }, [token, forceRender]);
  let pay_list: JSX.Element[] = [];
  let pay_list_names: string[] = [];

  if (!loading && pay) {
    pay_list_names = pay.map((elem) => elem.Name);
    pay_list = optionMaker(pay_list_names) ?? [];
  }

  const sendPayModify = async (
    e: React.FormEvent<HTMLFormElement>,
    payRequestObject,
    token: string
  ) => {
    e.preventDefault();

    try {
      await sendRequestWithToken("api/modify_pay", token, payRequestObject);
      setForceRender(!forceRender);
    } catch (e) {
      alert(e);
    }
  };

  if (loading === false) {
    return (
      <div>
        <MyNav />
        <Container>
          <br></br>
          <h2>Manage Payment Methods</h2>
          <br></br>
          {/* <Row> */}
          {/* <Col> */}
          <Form
            className="Pay_add-form"
            onSubmit={(e) =>
              // TODO: Decide on Logic
              sendPayModify(
                e,
                // Manually create payRequestObject
                {
                  name: newPayName,
                },
                token
              )
            }
          >
            {/* <Form.Row> */}
            <Form.Group controlId="add_name">
              <Form.Label>Add Payment Method</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name of payment method"
                size="lg"
                value={newPayName}
                onChange={(e) => setNewPayName(e.target.value)}
                autoFocus
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add
            </Button>
          </Form>
          {/* </Form.Row> */}
          {pay && (
            <Form
              onSubmit={(e) => {
                let payToModify = selectedPay;
                if (selectedPay === "") {
                  setSelectedPay(pay_list_names[0]);
                  payToModify = pay_list_names[0];
                }
                sendPayModify(
                  e,
                  // Manually create payRequestObject
                  {
                    name: payToModify,
                  },
                  token
                );
              }}
            >
              <br />
              <br />
              <Form.Group controlId="delete_name">
                <Form.Label>Delete/Disable Method</Form.Label>
                <Form.Control
                  as="select"
                  size="lg"
                  value={selectedPay}
                  onChange={(e) => setSelectedPay(e.target.value)}
                >
                  {pay_list}
                </Form.Control>
              </Form.Group>
              <Button variant="danger" type="submit">
                Delete
              </Button>
            </Form>
          )}
          {/* </Col> */}
          {/* </Row> */}
        </Container>
      </div>
    );
  } else {
    return <Loading />;
  }
};
