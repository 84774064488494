import React, { createContext, useState } from "react";
import { Page } from "../../Types";
import PropTypes from "prop-types";

const setCurrentPage: React.Dispatch<React.SetStateAction<Page>> = () =>
  Page.WELCOME;

export const CurrentPageContext = createContext({
  currentPage: Page.WELCOME,
  setCurrentPage,
});

export const CurrentPageProvider = ({ children }) => {
  const [currentPage, setCurrentPage] = useState(Page.WELCOME);

  return (
    <>
      <CurrentPageContext.Provider value={{ currentPage, setCurrentPage }}>
        {children}
      </CurrentPageContext.Provider>
    </>
  );
};

CurrentPageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
