// TODO: Remove this during refactor
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, ReactElement, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CurrentPageContext } from "./ContextProviders/CurrentPageProvider";
import { Page } from "../Types";

export const Register: React.FC = (): ReactElement => {
  const { setCurrentPage } = useContext(CurrentPageContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_conf, setPassword_conf] = useState("");

  const registerUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password_conf !== password) {
      alert("Passwords must match!");
      setPassword_conf("");
    } else {
      // Send registration data to API as JSON
      const data = { email: email, password: password };
      fetch("/auth/register", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) =>
        res.json().then((res) => {
          if (res.status === "success") {
            alert("Registration successful! You can now login.");
            setCurrentPage(Page.WELCOME);
          } else {
            alert(`Registration failed: ${res.message}`);
          }
        })
      );
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <br></br>
        <h1>Register</h1>
        <br></br>
      </header>
      <br></br>
      <Form onSubmit={registerUser}>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicConfirmPassword">
          <Form.Control
            type="password"
            placeholder="Confirm Password"
            value={password_conf}
            onChange={(e) => setPassword_conf(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Register
        </Button>
      </Form>
    </div>
  ) as ReactElement<never>;
};
