import React, { ReactElement } from "react";
import { Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { tableAmountSort } from "./Details";
import { ITransaction } from "../../Types";

const populatePayMethods = (in_data: ITransaction[]) => {
  const payMethodNames = in_data.map(
    (transaction) => transaction["Pay Method"]
  );
  const payMethodTotals: { [K in typeof payMethodNames[number]]: number } = {};
  in_data.forEach((transaction) => {
    // Init check for pay_method
    if (typeof payMethodTotals[transaction["Pay Method"]] === "undefined") {
      payMethodTotals[transaction["Pay Method"]] = parseFloat("0");
    }
    // Handle installments
    if (transaction["Installment Amount"]) {
      payMethodTotals[transaction["Pay Method"]] += parseFloat(
        transaction["Installment Amount"] as string
      );
    } else {
      payMethodTotals[transaction["Pay Method"]] += parseFloat(
        transaction.Amount
      );
    }
  });
  return payMethodTotals;
};

const pay_columns = [
  {
    dataField: "Pay Method",
    text: "Pay Method",
    sort: true,
  },
  {
    dataField: "Amount",
    text: "Amount",
    sort: true,
    sortFunc: (a: string, b: string, order: string, dataField, rowA, rowB) => {
      return tableAmountSort(a, b, order);
    },
  },
];

const defaultSorted = [
  {
    dataField: "Amount", // if dataField is not match to any column you defined, it will be ignored.
    order: "desc", // desc or asc
  },
];

const payTableFormat = (in_data: { [key: string]: number }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pay_data: { "Pay Method": string; Amount: string }[] = [];
  Object.keys(in_data).forEach((pay_method) => {
    pay_data.push({
      "Pay Method": pay_method,
      Amount: in_data[pay_method].toFixed(2),
    });
  });
  return pay_data;
};

export const PayTable = (props: {
  expData: ITransaction[];
  instData: ITransaction[];
}): ReactElement => {
  const payTableData = payTableFormat(
    populatePayMethods(props.instData.concat(props.expData))
  );
  return (
    <div>
      <Col sm>
        <br />
        <BootstrapTable
          keyField="Pay Method"
          data={payTableData}
          columns={pay_columns}
          striped
          hover
          bootstrap4
          condensed
          defaultSorted={defaultSorted}
        />
      </Col>
    </div>
  );
};
