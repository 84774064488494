import React, { ReactElement } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { tableAmountSort } from "./Details";
import { IRecordCategory, IRecordSubcategory } from "../../Types";

// Convert to react-bootstrap-table-2 format

const subcatTableFormat = (spendingBySubcategory: IRecordSubcategory) => {
  const subcat_data: { Subcategory: string; Amount: string }[] = [];
  Object.keys(spendingBySubcategory).forEach((subcategory) => {
    subcat_data.push({
      Subcategory: subcategory,
      Amount: spendingBySubcategory[subcategory].toFixed(2),
    });
  });
  return subcat_data;
};

const subcat_columns = [
  {
    dataField: "Subcategory",
    text: "Subcategory",
    sort: true,
  },
  {
    dataField: "Amount",
    text: "Amount",
    sort: true,
    sortFunc: (a: string, b: string, order: string, dataField, rowA, rowB) => {
      return tableAmountSort(a, b, order);
    },
  },
];

const defaultSorted = [
  {
    dataField: "Amount", // if dataField is not match to any column you defined, it will be ignored.
    order: "desc", // desc or asc
  },
];

export const SubcatTable = (props: {
  data: IRecordCategory;
  selectedCat: string;
}): ReactElement => {
  const subcatTableData = subcatTableFormat(props.data[props.selectedCat]);
  return (
    <div>
      <br />
      <h3>{props.selectedCat}</h3>
      <br />
      <BootstrapTable
        keyField="Subcategory"
        data={subcatTableData}
        columns={subcat_columns}
        striped
        hover
        bootstrap4
        condensed
        defaultSorted={defaultSorted}
      />
    </div>
  );
};
