import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../ContextProviders/UserProvider";
import { sendRequestWithToken } from "../../helpers/tokenManager";
import { AddCategory } from "./AddCategory";
import { RemoveCategory } from "./RemoveCategory";
import { AddSubcategory } from "./AddSubcategory";
import { RemoveSubcategory } from "./RemoveSubcategory";
import { Loading } from "../Loading";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Container,
  Nav,
  Tab,
} from "react-bootstrap";
import { MyNav } from "../MyNav";

interface CategoryResponse {
  Categories: APICategoryGroup;
}

interface APICategoryGroup {
  Expense: APICategory[];
  Income: APICategory[];
}

export interface APICategory {
  [key: string]: string[];
}

export enum CategoryType {
  EXPENSE = "Expense",
  INCOME = "Income",
}

export interface CategoryProps {
  CategoryType: CategoryType;
  Categories: APICategory[];
  setForceRender: () => void;
}

const showErrorAlert = (error) => {
  return <Alert variant="danger">{error}</Alert>;
};

export const Categories = () => {
  const [categoryType, setCategoryType] = useState<CategoryType>(
    CategoryType.EXPENSE
  );
  const [categories, setCategories] = useState<APICategoryGroup | null>(null);
  const [loading, setLoading] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const { token } = useContext(UserContext);

  const [activeKey, setActiveKey] = useState<string | undefined>("0");

  const handleSelect = (eventKey: string | null) => {
    setActiveKey(activeKey === eventKey ? undefined : eventKey || undefined);
  };

  const fetchCategories = React.useCallback(async () => {
    setLoading(true);
    try {
      const response = await sendRequestWithToken<void, CategoryResponse>(
        "get_categories",
        token
      );
      setCategories(response.Categories);
      console.log(response.Categories);
      setLoading(false);
    } catch (e) {
      showErrorAlert(e);
      setLoading(false);
    }
  }, [token]);

  useEffect(() => {
    fetchCategories();
  }, [token, fetchCategories, forceRender]);
  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <MyNav />
      <div className="m-3">
        <Container>
          <Tab.Container
            id="category-type-tabs"
            defaultActiveKey={categoryType}
          >
            <Nav variant="pills" className="justify-content-center">
              {Object.values(CategoryType).map((type) => (
                <Nav.Item key={type}>
                  <Nav.Link
                    eventKey={type}
                    onClick={() => setCategoryType(type)}
                  >
                    {type}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Tab.Container>

          <Accordion activeKey={activeKey} onSelect={handleSelect}>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Add Category
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <AddCategory
                    CategoryType={categoryType}
                    setForceRender={() => setForceRender((prev) => !prev)}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Remove Category
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <RemoveCategory
                    CategoryType={categoryType}
                    Categories={categories ? categories[categoryType] : []}
                    setForceRender={() => setForceRender((prev) => !prev)}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  Add Subcategory
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <AddSubcategory
                    CategoryType={categoryType}
                    Categories={categories ? categories[categoryType] : []}
                    setForceRender={() => setForceRender((prev) => !prev)}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  Remove Subcategory
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <RemoveSubcategory
                    CategoryType={categoryType}
                    Categories={categories ? categories[categoryType] : []}
                    setForceRender={() => setForceRender((prev) => !prev)}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
    </div>
  );
};
