import React, { useContext, useState } from "react";
import { CategoryProps } from "./Categories";
import { sendRequestWithToken } from "../../helpers/tokenManager";
import { UserContext } from "../ContextProviders/UserProvider";
import { Form, Button, Alert, Col } from "react-bootstrap";

export const AddCategory: React.FC<Omit<CategoryProps, "Categories">> = ({
  CategoryType,
  setForceRender,
}) => {
  const { token } = useContext(UserContext);
  const [newCategoryName, setNewCategoryName] = useState("");
  const showErrorAlert = (error) => {
    return <Alert variant="danger">{error}</Alert>;
  };
  const handleAddCategory = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await sendRequestWithToken("add_category", token, {
        name: newCategoryName,
        is_expense: CategoryType === "Expense" ? true : false,
      });
      setNewCategoryName("");
    } catch (e) {
      showErrorAlert(e);
    } finally {
      setForceRender();
    }
  };
  return (
    <Form onSubmit={handleAddCategory}>
      <Form.Row>
        <Col>
          <Form.Group controlId="categoryName">
            <Form.Control
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
            />
          </Form.Group>
        </Col>
        <Col sm="auto">
          <Button variant="primary" type="submit" disabled={!newCategoryName}>
            Add Category
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};
