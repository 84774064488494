import React, { useState, useEffect } from "react";
import logo from "../logo.svg";
// import { DebugLogin } from "./DebugLogin";
// eslint-disable-next-line
import { Login } from "./Login";
import { ITimeAPIResponse } from "../Types";

export const Welcome = () => {
  const [currentTime, setCurrentTime] = useState("");
  const [apiOnline, setApiOnline] = useState(false);

  useEffect(() => {
    const getTime = async () => {
      const time = await fetch("/api/time");
      const json_time = (await time.json()) as ITimeAPIResponse;
      setCurrentTime(json_time.time);
    };
    getTime().catch(alert);
    setApiOnline(true);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Welcome to Budgeteer!</h1>
        <br></br>
        {/*  <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a> */}
      </header>
      <p>
        {currentTime ? (
          `${currentTime}`
        ) : (
          <b style={{ color: "red", fontSize: "250%" }}>CONNECTING...</b>
        )}
      </p>
      {/* Use DebugLogin for autologin during development */}
      {/* <DebugLogin apiOnline={apiOnline}/> */}
      <Login apiOnline={apiOnline} />
    </div>
  );
  // Modify above line to return <Login /> before build;
};
