import React, { useContext, useEffect, useState } from "react";
import { CategoryProps } from "./Categories";
import { Alert, Form } from "react-bootstrap";
import { sendRequestWithToken } from "../../helpers/tokenManager";
import { UserContext } from "../ContextProviders/UserProvider";
import { SelectWithDelete } from "./SelectWithDelete";

export const RemoveSubcategory: React.FC<CategoryProps> = ({
  CategoryType,
  Categories,
  setForceRender,
}) => {
  const { token } = useContext(UserContext);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const showErrorAlert = (error) => {
    return <Alert variant="danger">{error}</Alert>;
  };

  useEffect(() => {
    setSelectedCategory("");
  }, [CategoryType]);

  const handleDeleteSubcategory = async (subcategoryName: string) => {
    try {
      const isExpense = CategoryType === "Expense";
      await sendRequestWithToken("del_subcategory", token, {
        cat_name: selectedCategory,
        subcat_name: subcategoryName,
        is_expense: isExpense,
      });
    } catch (e) {
      showErrorAlert(e);
    } finally {
      setForceRender();
    }
  };

  return (
    <div>
      <Form.Group controlId="categorySelect">
        <Form.Control
          as="select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {Object.keys(Categories).map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      {selectedCategory && (
        <SelectWithDelete
          selectOptions={Categories[selectedCategory]}
          onDelete={handleDeleteSubcategory}
          deleteButtonLabel="Remove Subcategory"
        />
      )}
    </div>
  );
};
