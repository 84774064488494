import React, { ReactElement, useState, useEffect, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import { DashGraph } from "./DashGraph";
import Nav from "react-bootstrap/Nav";
import {
  fetchData,
  transactMonthFilter,
  computeCatTotals,
} from "../../helpers/helpers";
import "./Dashboard.css";
import { UserContext } from "../ContextProviders/UserProvider";
import {
  AllData,
  IRecordByCategory,
  IRecordSubcategory,
  ITransaction,
} from "../../Types";
import { FormType, TransactionForm } from "./TransactionForm";
import { Loading } from "../Loading";

// For using in calcs, currently fixed on this month

const current_date: Date = new Date();
const current_month: number = current_date.getMonth() + 1;
const current_year: number = current_date.getFullYear();

interface SummaryAmount {
  totalIncome: number;
  totalExpense: number;
  totalBalance: number;
}

interface FormattedSummaryAmount {
  totalIncome: string;
  totalExpense: string;
  totalBalance: string;
}

// Create <option>s for given list
export const optionMaker = (list: string[]): JSX.Element[] => {
  try {
    list = list.sort((a, b) => (a.toUpperCase() < b.toUpperCase() ? -1 : 1));
    return list.map((elem) => <option key={elem.toString()}>{elem}</option>);
  } catch (e) {
    console.warn(
      `Cannot create list due to ${JSON.stringify(e)}, list is ${list}`
    );
  }
  return [];
};

export const transactSummaryMaker = (list: AllData, is_expense: boolean) => {
  // Process expenses or incomes

  let listToProcess: ITransaction[] = is_expense
    ? list.Transactions.Transact_Exp
    : list.Transactions.Transact_Inc;

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
  };

  listToProcess = transactMonthFilter(
    listToProcess,
    current_month,
    current_year
  );
  // Sort by Desc Date
  listToProcess = listToProcess.sort((a, b) => (a.Date < b.Date ? 1 : -1));
  if (is_expense) {
    return listToProcess.map((elem) => (
      <tr key={elem.id}>
        <td>{elem.Date.toLocaleDateString("tr-TR", options)}</td>
        <td>{elem.Category}</td>
        <td>{elem.Subcategory}</td>
        <td>{elem.Amount}</td>
      </tr>
    ));
  } else {
    return listToProcess.map((elem) => (
      <tr style={{ color: "#008000" }} key={elem.id}>
        <td>{elem.Date.toLocaleDateString("tr-TR", options)}</td>
        <td>{elem.Category}</td>
        <td>{elem.Subcategory}</td>
        <td>+{elem.Amount}</td>
      </tr>
    ));
  }
};

// This function calculates the numbers to show in Income | Expense | Balance stuff
const calculateSummaryAmounts = (
  RecordsByCategory: IRecordByCategory
): FormattedSummaryAmount => {
  // Helper func to calculate total of a category

  const addCats = (Records: IRecordSubcategory) => {
    let sumHolder = 0;
    Object.keys(Records).forEach((subcategory) => {
      sumHolder += Records[subcategory];
    });
    return sumHolder;
  };

  const [totalIncome, totalExpense, totalBalance]: Array<number> = [0, 0, 0];

  const summary: SummaryAmount = {
    totalIncome,
    totalExpense,
    totalBalance,
  };

  Object.keys(RecordsByCategory["Expense"]).forEach((category) => {
    const currentSum: number = addCats(RecordsByCategory["Expense"][category]);
    summary.totalExpense += currentSum;
  });

  Object.keys(RecordsByCategory.Income).forEach((category) => {
    const currentSum: number = addCats(RecordsByCategory["Income"][category]);
    summary.totalIncome += currentSum;
  });

  summary.totalBalance = summary.totalIncome - summary.totalExpense;

  // Iterate through summary and format everything to 2 decimal places as a string
  const formattedSummary: FormattedSummaryAmount = {
    totalIncome: summary.totalIncome.toFixed(2),
    totalExpense: summary.totalExpense.toFixed(2),
    totalBalance: summary.totalBalance.toFixed(2),
  };

  return formattedSummary;
};

export const Dashboard: React.FC = (): ReactElement => {
  // Fetch categories, subcategories and pay_methods

  const { token } = useContext(UserContext);
  const [data, setData] = useState<AllData>();
  const [loading, setLoading] = useState(true);
  const [forceRender, setForceRender] = useState(false);

  // Return object will have {"Expense", "Income", "PayMethods" and nested values as categories-subcategories}

  useEffect(() => {
    fetchData(setData, setLoading, token).catch(alert);
  }, [token, forceRender]);

  if (loading === false && data != null) {
    const transact_summary_exp = transactSummaryMaker(data, true);
    const transact_summary_inc = transactSummaryMaker(data, false);
    const RecordsByCategory = computeCatTotals(
      data,
      current_month,
      current_year
    );
    const summary: FormattedSummaryAmount =
      calculateSummaryAmounts(RecordsByCategory);

    return (
      <div className="App">
        <header className="App-header">
          <p />
          <h1>Dashboard</h1>
        </header>
        <br></br>
        <Container>
          <Row>
            <Col>
              <Tab.Container id="Dash-tabs" defaultActiveKey="expense">
                <Nav variant="pills" className="justify-content-center">
                  <Nav.Link eventKey="expense">Expense</Nav.Link>
                  <Nav.Link eventKey="income">Income</Nav.Link>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="expense">
                    {/* Expense */}
                    <TransactionForm
                      formType={FormType.EXPENSE}
                      data={data}
                      forceRender={forceRender}
                      setForceRender={setForceRender}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="income">
                    {/* Income */}
                    <TransactionForm
                      formType={FormType.INCOME}
                      data={data}
                      forceRender={forceRender}
                      setForceRender={setForceRender}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
              {/* <Row>
              <Expense />
            </Row> */}
              <Row>
                <br />
              </Row>
            </Col>
            <Col sm className="justify-content-md-center">
              <h3>Summary for Current Month</h3>
              <Container fluid>
                <Row className="dashSumTotalsRow">
                  <Col className="dashSumTotalIncomeCol">
                    Income
                    <br />
                    {summary.totalIncome}
                  </Col>
                  <Col className="dashSumTotalExpenseCol">
                    Expense
                    <br />
                    {summary.totalExpense}
                  </Col>
                  {Number(summary.totalBalance) >= 0 ? (
                    <Col className="dashSumTotalBalancePositiveCol">
                      Balance
                      <br />
                      {summary.totalBalance}
                    </Col>
                  ) : (
                    <Col className="dashSumTotalBalanceNegativeCol">
                      Balance
                      <br />
                      {summary.totalBalance}
                    </Col>
                  )}
                </Row>
              </Container>
              <br></br>
              <DashGraph SpendingByCategory={RecordsByCategory.Expense} />
              <Table striped bordered hover size="sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Category</th>
                    <th>Subcat</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {transact_summary_inc}
                  {transact_summary_exp}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return <Loading hideMyNav />;
  }
};
