import React, { useContext } from "react";
import { CategoryProps, CategoryType } from "./Categories";
import { SelectWithDelete } from "./SelectWithDelete";
import { Alert } from "react-bootstrap";
import { sendRequestWithToken } from "../../helpers/tokenManager";
import { UserContext } from "../ContextProviders/UserProvider";

export const RemoveCategory: React.FC<CategoryProps> = ({
  CategoryType: CatType,
  Categories,
  setForceRender,
}) => {
  const { token } = useContext(UserContext);
  const showErrorAlert = (error) => {
    return <Alert variant="danger">{error}</Alert>;
  };
  const handleDeleteCategory = async (categoryName: string) => {
    try {
      const isExpense = CatType === CategoryType.EXPENSE;
      await sendRequestWithToken("del_category", token, {
        name: categoryName,
        is_expense: isExpense,
      });
    } catch (e) {
      showErrorAlert(e);
    } finally {
      setForceRender();
    }
  };

  return (
    <div>
      <SelectWithDelete
        selectOptions={Object.keys(Categories)}
        onDelete={handleDeleteCategory}
        deleteButtonLabel="Remove Category"
      />
    </div>
  );
};
