import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

const setToken: React.Dispatch<React.SetStateAction<string>> = () => "";

export const UserContext = createContext({
  token: "",
  setToken,
});

export const UserProvider = ({ children }) => {
  const [token, setToken] = useState("");

  return (
    <>
      <UserContext.Provider value={{ token, setToken }}>
        {children}
      </UserContext.Provider>
    </>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
