import React, {
  useState,
  ReactElement,
  useContext,
  useEffect,
  useRef,
} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";
import { optionMaker } from "./Dashboard";
import { sendRequestWithToken } from "../../helpers/tokenManager";
import {
  Discount,
  DiscountType,
  FormFields,
  useFormFields,
} from "../../helpers/hooksLib";
import { UserContext } from "../ContextProviders/UserProvider";
import { AllData } from "../../Types";

const DEFAULT_INSTALLMENTS = 2;
const DEFAULT_DISCOUNT = 10;

export enum FormType {
  EXPENSE = "Expense",
  INCOME = "Income",
}

export const TransactionForm = (props: {
  data: AllData;
  forceRender: boolean;
  setForceRender: React.Dispatch<React.SetStateAction<boolean>>;
  formType: FormType;
}): ReactElement => {
  const { data, forceRender, setForceRender, formType } = props;

  const transactionType = formType;
  const isExpenseForm = transactionType === FormType.EXPENSE;

  // Set states
  const [categories] = useState<string[]>(
    Object.keys(data.Categories[transactionType])
  );
  const [subcategories, setSubcategories] = useState<string[]>(
    data.Categories[transactionType][categories[0]] as string[]
  );
  const [pay_methods] = useState(data.PayMethods) ?? undefined;
  const [installmentBox, setInstallmentBox] = useState(false);
  const [discountBox, setDiscountBox] = useState(false);
  const [discount, setDiscount] = useState<Discount>({
    type: DiscountType.PERCENT,
    value: DEFAULT_DISCOUNT,
  });

  const prevDiscountRef = useRef<Discount>(discount);

  const [cardBox, setCardBox] = useState(false);
  const { token } = useContext(UserContext);

  const initForm: FormFields = {
    date: new Date().toISOString().substring(0, 10),
    cat: categories[0],
    subcat: subcategories[0],
    details: "",
    amount: "",
    installments: null,
    discount: null,
  };

  const {
    fields,
    handleFieldChange,
    resetForm,
    handleCatChange,
    handleDiscountChange,
    updateDiscountInFields,
  } = useFormFields(initForm);

  useEffect(() => {
    if (
      prevDiscountRef.current.type !== discount.type ||
      prevDiscountRef.current.value !== discount.value
    ) {
      updateDiscountInFields(discount);
      prevDiscountRef.current = discount;
    }
  }, [discount, updateDiscountInFields]);

  const discountTypes = Object.values(DiscountType);

  const [cat_list, subcat_list, pay_list, discount_types] = [
    categories,
    subcategories,
    pay_methods,
    discountTypes,
  ].map((array) => optionMaker(array));

  // Handle form submission
  const handleSubmitTransaction = async (
    event: React.FormEvent<HTMLFormElement>,
    fields: FormFields,
    token: string,
    forceRender: boolean,
    setForceRender: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    event.preventDefault();
    try {
      await sendRequestWithToken("api/add_transact", token, fields);
      setForceRender(!forceRender);
    } catch (e) {
      alert(`error caught ${JSON.stringify(e)}`);
    }
  };

  // Handle form reset
  const handleFormReset = (
    initForm: FormFields,
    resetForm: (initForm: FormFields) => void,
    setInstallmentBox: React.Dispatch<React.SetStateAction<boolean>>,
    setCardBox: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    resetForm(initForm);
    setInstallmentBox(false);
    setCardBox(false);
  };

  // Handle card box
  const handleCardBox = (
    cardBox: boolean,
    setCardBox: React.Dispatch<React.SetStateAction<boolean>>,
    fields: FormFields,
    pay_methods: string[]
  ) => {
    if (cardBox === false) {
      setCardBox(true);
      fields.cardType = pay_methods[0];
    } else {
      setCardBox(false);
      // Remove cardType
      delete fields["cardType"];
    }
  };

  // Handle installment box
  const handleInstallmentBox = (
    installmentBox: boolean,
    setInstallmentBox: React.Dispatch<React.SetStateAction<boolean>>,
    fields: FormFields
  ) => {
    if (installmentBox === false) {
      setInstallmentBox(true);
      fields.installments = DEFAULT_INSTALLMENTS;
    } else {
      setInstallmentBox(false);
      fields.installments = null;
    }
  };

  const handleDiscountBox = (
    discountBox: boolean,
    setDiscountBox: React.Dispatch<React.SetStateAction<boolean>>,
    fields: FormFields
  ) => {
    if (discountBox === false) {
      setDiscountBox(true);
      fields.discount = {
        type: DiscountType.PERCENT,
        value: DEFAULT_DISCOUNT,
      };
    } else {
      setDiscountBox(false);
      fields.discount = null;
    }
  };

  return (
    <Form
      className="Transact-form"
      onSubmit={(e) =>
        handleSubmitTransaction(e, fields, token, forceRender, setForceRender)
      }
      onReset={() =>
        handleFormReset(initForm, resetForm, setInstallmentBox, setCardBox)
      }
    >
      <Form.Group controlId="date">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="date"
          value={fields.date}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="cat">
        <Form.Label>Category</Form.Label>
        <Form.Control
          as="select"
          value={fields.cat}
          onChange={(e) =>
            handleCatChange(
              e as React.ChangeEvent<HTMLSelectElement>,
              setSubcategories,
              data.Categories[transactionType][e.target.value] as string[]
            )
          }
          required
        >
          {cat_list}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="subcat">
        <Form.Label>Subcategory</Form.Label>
        <Form.Control
          as="select"
          value={fields.subcat}
          onChange={handleFieldChange}
          required
        >
          {subcat_list}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="details">
        <Form.Label>Details</Form.Label>
        <Form.Control
          type="text"
          placeholder="Details about the transaction"
          value={fields.details}
          onChange={handleFieldChange}
        />
      </Form.Group>
      <Form.Group controlId="amount">
        <Form.Control
          type="number"
          placeholder="Amount"
          step="0.01"
          value={fields.amount}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      {isExpenseForm && (
        <Form.Row>
          <Form.Group controlId="cardCheckbox">
            <Form.Check
              inline
              type="switch"
              label="Pay By Card"
              checked={cardBox}
              onChange={() =>
                handleCardBox(cardBox, setCardBox, fields, pay_methods)
              }
            />
          </Form.Group>
          {cardBox && (
            <Col>
              <Form.Group controlId="cardType">
                <Form.Control
                  as="select"
                  size="sm"
                  value={fields.cardType}
                  onChange={handleFieldChange}
                >
                  {pay_list}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
        </Form.Row>
      )}
      {isExpenseForm && (
        <Form.Row>
          <Form.Group controlId="installmentCheckbox">
            <Form.Check
              inline
              type="switch"
              label="Installments"
              checked={installmentBox}
              onChange={() =>
                handleInstallmentBox(installmentBox, setInstallmentBox, fields)
              }
            />
          </Form.Group>
          {installmentBox && (
            <Col>
              <Form.Group controlId="installments">
                <Form.Control
                  size="sm"
                  type="number"
                  placeholder="(e.g: 2)"
                  min={2}
                  value={
                    fields.installments !== null
                      ? fields.installments.toString()
                      : ""
                  }
                  onChange={handleFieldChange}
                />
              </Form.Group>
            </Col>
          )}
        </Form.Row>
      )}
      {isExpenseForm && (
        <Form.Row>
          <Form.Group controlId="discountCheckbox">
            <Form.Check
              inline
              type="switch"
              label="Discount"
              checked={discountBox}
              onChange={() =>
                handleDiscountBox(discountBox, setDiscountBox, fields)
              }
            />
          </Form.Group>
          {discountBox && (
            <>
              <Col xs={4}>
                <Form.Control
                  id="discountType"
                  as="select"
                  size="sm"
                  value={fields.discount?.type}
                  onChange={(e) =>
                    handleDiscountChange(
                      e as React.ChangeEvent<HTMLSelectElement>,
                      setDiscount,
                      discount
                    )
                  }
                >
                  {discount_types}
                </Form.Control>
              </Col>
              <Col xs={4}>
                <Form.Control
                  id="discountValue"
                  size="sm"
                  type="number"
                  placeholder="(e.g: 10)"
                  min={0}
                  max={
                    fields.discount?.type === DiscountType.PERCENT
                      ? 100
                      : undefined
                  }
                  value={fields.discount?.value.toString() ?? ""}
                  onChange={(e) =>
                    handleDiscountChange(
                      e as React.ChangeEvent<HTMLInputElement>,
                      setDiscount,
                      discount
                    )
                  }
                />
              </Col>
            </>
          )}
        </Form.Row>
      )}
      <Form.Row>
        <Col xs={8}>
          <Button variant="primary" type="submit" block>
            Submit
          </Button>
        </Col>
        <Col>
          <Button variant="secondary" type="reset" block>
            Clear
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
};
