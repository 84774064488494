import React, { ReactElement, useState } from "react";
import { Col, Row } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { tableAmountSort } from "./Details";
import { SubcatTable } from "./SubcatTable";
import { IRecordByCategory, IRecordCategory } from "../../Types";

const catTableFormat = (SpendingByCategory: IRecordCategory) => {
  const expense_data: { Category: string; Amount: string }[] = [];
  Object.keys(SpendingByCategory).forEach((category) => {
    let cat_total = 0;
    // Sum all subcats of each category
    Object.keys(SpendingByCategory[category]).forEach((subcategory) => {
      cat_total += SpendingByCategory[category][subcategory];
    });
    // Convert to table format
    expense_data.push({ Category: category, Amount: cat_total.toFixed(2) });
  });
  return expense_data;
};

const cat_columns = [
  {
    dataField: "Category",
    text: "Category",
    sort: true,
  },
  {
    dataField: "Amount",
    text: "Amount",
    sort: true,
    sortFunc: (a: string, b: string, order, dataField, rowA, rowB) => {
      return tableAmountSort(a, b, order as string);
    },
  },
];

const defaultSorted = [
  {
    dataField: "Amount", // if dataField is not match to any column you defined, it will be ignored.
    order: "desc", // desc or asc
  },
];

export const CatTable = (props: { data: IRecordByCategory }): ReactElement => {
  const catTableData = catTableFormat(props.data.Expense);
  // Init with empty string, this will show select cat message on first render
  const [selectedCat, setSelectedCat] = useState("");

  const rowEvents = {
    onClick: (e, row: { Category: string; Amount: string }, index) => {
      // Read category by
      setSelectedCat(row.Category);
    },
  };
  // prettier-ignore
  return (
    <div>
      <Row>
        <Col sm>
          <br />
          <BootstrapTable
            keyField="Category"
            data={catTableData}
            columns={cat_columns}
            striped
            hover
            bootstrap4
            condensed
            defaultSorted={defaultSorted}
            rowEvents={rowEvents}
          />
        </Col>
        <Col sm className="absolute-center">
          
          {/* @ts-ignore */}
          {selectedCat === "" && (
            <h2 style={{ color: "#aaa" }}>
              <br />
              <br />
              <br />
              <br />
              Select a Category for Details
            </h2>
          )}
          
          {/* @ts-ignore */}
          {selectedCat !== "" && ( <SubcatTable data={props.data.Expense} selectedCat={selectedCat} />
          )}
        </Col>
      </Row>
    </div>
  );
};
