import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getToken } from "../helpers/tokenManager";
import { UserContext } from "./ContextProviders/UserProvider";
import { CurrentPageContext } from "./ContextProviders/CurrentPageProvider";
import { Page } from "../Types";

interface ILoginProps {
  apiOnline: boolean;
}

export const Login = (props: ILoginProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setToken } = useContext(UserContext);
  const { setCurrentPage } = useContext(CurrentPageContext);

  const loginUser = (e: React.FormEvent) => {
    e.preventDefault();

    // Send registration data to API as JSON
    if (email && password) {
      getToken(email, password, setToken)
        .then(
          () => {
            console.log(`Logged in! Welcome ${email}!`);
            setCurrentPage(Page.HOME);
          },
          () => undefined
        )
        .catch(() => console.log("Error"));
    } else {
      alert("Please enter your e-mail and password.");
    }
  };

  if (props.apiOnline) {
    return (
      <Form className="Login-form" onSubmit={loginUser}>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        {/* <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label="Remember me (Not implemented)"
          disabled
        />
      </Form.Group> */}
        <Button variant="primary" type="submit">
          Login
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="secondary"
          onClick={() => setCurrentPage(Page.REGISTER)}
        >
          {" "}
          Register{" "}
        </Button>
      </Form>
    );
  } else {
    return <h5>Please wait...</h5>;
  }
};
