import React, { useContext } from "react";
import "./App.css";
import { Register } from "./components/Register";
import { Welcome } from "./components/Welcome";
import { Home } from "./components/Home";
import { Details } from "./components/Details/Details";
import { Pay } from "./components/Pay";
import { Page } from "./Types";
import { CurrentPageContext } from "./components/ContextProviders/CurrentPageProvider";
import { Categories } from "./components/Categories/Categories";

const Router = () => {
  const { currentPage } = useContext(CurrentPageContext);

  switch (currentPage) {
    case Page.WELCOME:
      return <Welcome />;

    case Page.REGISTER:
      return <Register />;

    case Page.HOME:
      return <Home />;

    case Page.DETAILS:
      return <Details />;

    case Page.PAY:
      return <Pay />;

    case Page.CATEGORIES:
      return <Categories />;

    default:
      return <div>404, please refresh page.</div>;
  }
};

export default Router;
