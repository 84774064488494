import React, { ReactElement, useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import logo from "../logo.svg";
import Nav from "react-bootstrap/Nav";
import { UserContext } from "./ContextProviders/UserProvider";
import { CurrentPageContext } from "./ContextProviders/CurrentPageProvider";
import { Page } from "../Types";

export const MyNav: React.FC = (): ReactElement => {
  const { setToken } = useContext(UserContext);
  const { setCurrentPage } = useContext(CurrentPageContext);

  return (
    <Navbar className="MyNavbar" bg="dark" variant="dark" expand="sm">
      <Navbar.Brand
        title="Dashboard"
        onClick={() => {
          setCurrentPage(Page.HOME);
        }}
      >
        <img
          alt=""
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />
        {""}
        Budgeteer
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link
            title="Dashboard"
            onClick={() => {
              setCurrentPage(Page.HOME);
            }}
          >
            Dashboard
          </Nav.Link>
          <Nav.Link
            title="Details"
            onClick={() => {
              setCurrentPage(Page.DETAILS);
            }}
          >
            Details
          </Nav.Link>

          <Nav.Link
            title="Categories"
            onClick={() => setCurrentPage(Page.CATEGORIES)}
          >
            Categories
          </Nav.Link>
          <Nav.Link
            title="Pay Methods"
            onClick={() => {
              setCurrentPage(Page.PAY);
            }}
          >
            Pay Methods
          </Nav.Link>
          <Nav.Link
            title="Logout"
            onClick={() => {
              setToken("");
              setCurrentPage(Page.WELCOME);
            }}
          >
            Logout
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
