import React from "react";
import { MyNav } from "./MyNav";

interface LoadingProps {
  hideMyNav?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ hideMyNav = false }) => {
  return (
    <div>
      {!hideMyNav && <MyNav />}
      <h1>LOADING</h1>
    </div>
  );
};
