import React from "react";
import "./App.css";
import Router from "./Router";
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from "./components/ContextProviders/UserProvider";
import { CurrentPageProvider } from "./components/ContextProviders/CurrentPageProvider";

const App = () => {
  return (
    <UserProvider>
      <CurrentPageProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </CurrentPageProvider>
    </UserProvider>
  );
};

export default App;
