import React, { useState } from "react";

export enum DiscountType {
  PERCENT = "Percent",
  AMOUNT = "Amount",
}
export interface Discount {
  type: DiscountType;
  value: number;
}
export interface FormFields {
  date: string;
  cat: string;
  subcat: string;
  details: string;
  amount: string;
  discount: Discount | null;
  installments: number | null;
  cardType?: string;
}

export function useFormFields<T>(initialState: T) {
  const [fields, setFields] = useState<T>(initialState);

  return {
    fields,
    // Set values for generic form field change
    handleFieldChange: function (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) {
      setFields({
        ...fields,
        [event.target.id]: event.target.value,
      });
    },
    // Set init values from a given object
    resetForm: function (init: T) {
      setFields(init);
    },
    // Custom func to handle cat change, where changing a cat needs to select the cat[subcat][0] for the new cat
    // ...
    handleCatChange: function (
      event: React.ChangeEvent<HTMLSelectElement>,
      setSubcategories: React.Dispatch<React.SetStateAction<string[]>>,
      newSubcategories: string[]
    ) {
      const newCat = event.target.value;
      setSubcategories(newSubcategories);
      setFields({
        ...fields,
        [event.target.id]: newCat,
        subcat: newSubcategories[0],
      });
    },
    handleDiscountChange: function (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
      setDiscount: React.Dispatch<React.SetStateAction<Discount>>,
      discount: Discount
    ) {
      event.preventDefault();

      const newDiscount: Discount = { ...discount };

      if (event.target.id === "discountType") {
        newDiscount.type = event.target.value as DiscountType;
      } else {
        newDiscount.value = Number(event.target.value);
      }
      setDiscount(newDiscount);
    },
    updateDiscountInFields: function (discount: Discount) {
      setFields({
        ...fields,
        discount,
      });
    },
  };
}
