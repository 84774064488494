import React, { useContext, useEffect, useState } from "react";
import { CategoryProps } from "./Categories";
import { sendRequestWithToken } from "../../helpers/tokenManager";
import { UserContext } from "../ContextProviders/UserProvider";
import { Form, Button, Alert, Col } from "react-bootstrap";

export const AddSubcategory: React.FC<CategoryProps> = ({
  CategoryType,
  Categories,
  setForceRender,
}) => {
  const { token } = useContext(UserContext);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [newSubcategoryName, setNewSubcategoryName] = useState("");

  const showErrorAlert = (error) => {
    return <Alert variant="danger">{error}</Alert>;
  };

  useEffect(() => {
    setSelectedCategory("");
    setNewSubcategoryName("");
  }, [CategoryType]);

  const handleAddSubcategory = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await sendRequestWithToken("add_subcategory", token, {
        cat_name: selectedCategory,
        subcat_name: newSubcategoryName,
        is_expense: CategoryType === "Expense" ? true : false,
      });
      setNewSubcategoryName("");
      setSelectedCategory("");
    } catch (e) {
      showErrorAlert(e);
    } finally {
      setForceRender();
    }
  };

  if (!Categories || Categories.length === 0) {
    return <>No categories found!</>;
  }

  return (
    <Form onSubmit={handleAddSubcategory}>
      <Form.Group controlId="categorySelect">
        <Form.Control
          as="select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {Object.keys(Categories).map((category: string, index: number) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      {selectedCategory && (
        <Form.Row className="align-items-center">
          <Col>
            <Form.Group controlId="subcategoryName" className="mb-0">
              <Form.Control
                type="text"
                value={newSubcategoryName}
                onChange={(e) => setNewSubcategoryName(e.target.value)}
                placeholder="Enter subcategory name"
              />
            </Form.Group>
          </Col>
          <Col sm="auto">
            <Button
              variant="primary"
              type="submit"
              disabled={!newSubcategoryName}
            >
              Add Subcategory
            </Button>
          </Col>
        </Form.Row>
      )}
    </Form>
  );
};
