import React, { ReactElement } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import { ITransaction } from "../../Types";
import { sendDeleteRequest, tableAmountSort } from "./Details";

export const dateFormat = (in_data: ITransaction[]) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const returnData = in_data.map((elem) => {
    return {
      ...elem,
      Date: elem.Date.toLocaleDateString("tr-TR", options),
    };
  });

  return returnData;
};

const delButton = (
  cell,
  row: { id: number },
  rowIndex,
  props: {
    token: string;
    forceRender: boolean;
    setForceRender: React.Dispatch<React.SetStateAction<boolean>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  }
) => {
  return (
    <Button
      variant="outline-danger"
      size="sm"
      onClick={() =>
        sendDeleteRequest(
          row.id,
          false,
          props.token,
          props.forceRender,
          props.setForceRender,
          props.setLoading
        )
      }
    >
      Delete
    </Button>
  );
};

const defaultSorted = [
  {
    dataField: "Date",
    order: "asc",
  },
];

export const TransactionTable = (
  props: TransactionTableProps
): ReactElement => {
  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
    },
    {
      dataField: "Date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "Category",
      text: "Category",
      sort: true,
    },
    {
      dataField: "Subcategory",
      text: "Subcategory",
    },
    {
      dataField: "Details",
      text: "Details",
    },
    ...(props.showPayMethod
      ? [
          {
            dataField: "Pay Method",
            text: "Pay Method",
            sort: true,
          },
        ]
      : []),
    {
      dataField: "Amount",
      text: "Amount",
      sort: true,
      sortFunc: (
        a: string,
        b: string,
        order: string,
        dataField,
        rowA,
        rowB
      ) => {
        return tableAmountSort(a, b, order);
      },
    },
    {
      dataField: "button",
      text: "Button",
      formatter: delButton,
      formatExtraData: props,
    },
  ];
  const tableData = dateFormat(props.data);
  return (
    <div>
      <br />
      <BootstrapTable
        keyField="id"
        data={tableData}
        columns={columns}
        striped
        hover
        bootstrap4
        condensed
        defaultSorted={defaultSorted}
      />
    </div>
  );
};

interface TransactionTableProps {
  data: ITransaction[];
  token: string;
  forceRender: boolean;
  setForceRender: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showPayMethod: boolean;
}
