import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";

interface SelectWithDeleteProps {
  selectOptions: string[];
  onDelete: (category: string) => void;
  deleteButtonLabel?: string;
}

export const SelectWithDelete: React.FC<SelectWithDeleteProps> = ({
  selectOptions,
  onDelete,
  deleteButtonLabel = "Delete",
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedForDelete, setSelectedForDelete] = useState<string | null>(
    selectOptions && selectOptions.length > 0 ? selectOptions[0] : null
  );

  useEffect(() => {
    if (selectOptions && selectOptions.length > 0) {
      setSelectedForDelete(selectOptions[0]);
    }
  }, [selectOptions]);

  const handleDelete = (selectedForDelete: string | null) => {
    if (!selectedForDelete) {
      alert("Please select a category before deleting.");
      return;
    }
    setSelectedForDelete(selectedForDelete);
    setShowModal(true);
  };

  const confirmDelete = () => {
    if (selectedForDelete) {
      onDelete(selectedForDelete);
    }
    setShowModal(false);
  };

  if (!selectOptions || selectOptions.length === 0) {
    return <></>;
  }

  return (
    <>
      <Form.Row>
        <Col>
          <Form.Group controlId="categorySelect">
            <Form.Control
              as="select"
              onChange={(e) => setSelectedForDelete(e.target.value)}
            >
              {selectOptions.map((selectOption, index) => (
                <option key={index}>{selectOption}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="auto">
          <Form.Group controlId="deleteButton">
            <Button
              variant="danger"
              onClick={() => handleDelete(selectedForDelete)}
            >
              {deleteButtonLabel}
            </Button>
          </Form.Group>
        </Col>
      </Form.Row>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {selectedForDelete}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
